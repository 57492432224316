.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    width: 100%;
    min-height: 100vh;
    margin: 0;
  }
  
  .App-header {
    background-color: white;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
  }
  
  .App-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
  }
  
  .card {
    /* background-color: whitesmoke; */
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    border: 1px solid gray;
  }
  
  .video-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .video-group {
      /* flex-direction: row; */
      gap: 1rem;
    }
  }
  
  .video-player {
    width: 100%;
    position: relative;
  }
  
  /* Center the play icon in the Video.js player */
  .vjs-big-play-button {
    top: 50% !important;
    left: 50% !important;
    margin-top: -2rem !important;
    margin-left: -3rem !important;
  }
  
  /* Fix the play button position after the video has started */
  .video-js.vjs-playing .vjs-big-play-button {
    display: none;
  }

  .label {
    z-index: 99;
    position: absolute;
    top: 5px;
    left: 5px;
    min-width: 36px;
    padding: 2px;
    height: 30px;
    background-color: whitesmoke;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
  }

  .reportHeader {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: medium;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .reportHeaderElemL {
    color: black;
    margin-top: 20px;
    font-size: 20px;
    width: 100%;
  }
  .reportHeaderElemR {
    width: 100%;
    /* text-align: right; */
    color: #707c87;
    font-size: 15px;
    font-weight: bold;
  }

  .line {
    width: 95%;
    border: 1.5px solid #707c87;
    margin: 0;
    margin-top: 5px;
  }

  .feedbackTitle {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    color: black;
    font-size: 20px;
    text-align: left;
  }

  .feedbackContent {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    text-align: left;
    color: #707c87;
    font-size: 15px;
    font-weight: bold;
  }


  
.accordion {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .accordion-title {
    background-color: #f5f5f5;
    cursor: pointer;
    font-weight: bold;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-content {
    padding: 1rem;
    border-top: 1px solid #ccc;
  }