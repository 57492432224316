.calendar {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  padding:5px;
}

.calendar th,
.calendar td {
  /* border: 1px solid #ccc; */
  padding: 0.5rem;
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 480px) {
  .calendar th,
  .calendar td {
    font-size: 0.8rem;
  }
}